.ThreeBoxDetail-Heading{
    color: white;
    font-weight: 600;
    font-size: 24px;
    padding: 10px;
}
.ThreeBoxDetail-Content{
    color: white;
    font-size: 18px;
    padding-left: 10px !important;
    text-align: left;
    line-height: 24px;
}
.ListGreyTextSection-ListItem{
    list-style-position: outside;
    margin-left: 1.5em
}
.ListGrey-Container{
    margin: "10px 0";
    padding: 20 ;
}

@media only screen and (max-width: 760px) {
    .ListGreyTextSection-ListItem {
        margin-left: 0
    }
    .ListGrey-Container{
        margin: "0";
        padding: 0 ;
    }
}

