.headingSectionImageTitle{
    font-size: 26px;
    line-height: 24px !important;
    color: #114675;
    padding: 0 0 0 10px;
    font-weight: 600;
}
.headingSectionImageBody{
    font-size: 17px;
    line-height: 24px !important;
    padding: 15px;

}

.headingSectionImageBody-Image{
    margin: 0;
}

@media only screen and (max-width: 760px) {
    .headingSectionImageBody-Image{
        margin-top: 50px !important;
    }
}