
/* .newSectionItem:hover .hiddenSegment{
  height: 19.5em;
  transition: height 300ms;
  opacity: 1;
}

.hiddenSegment {
  padding: 5px;
  height: 3em;
  width: 97%;
  margin: 0 0 0 0;
  background-color: #ebeaea;
  display: block;
  overflow: hidden;
  transition: height 300ms;
} */
.para-body {
  font-size: 15px;
}
@media only screen and (max-width: 760px) {
  /* .hiddenSegment {
    padding: 5px;
    height: 3em;
    width: 100%;
    margin: 0 0 0 0;
    background-color: #ebeaea;
    display: block;
    overflow: hidden;
    transition: height 300ms;
  } */
  .carousel .slider {
    height: 60vh;
  }
  /* .newSectionItem:hover .hiddenSegment{
    height: 20em;
    transition: height 300ms;
    opacity: 1;
  } */
}

.titleHeading {
  color: #114675;
  text-align: center;
  /*font-size: 24px !important;
  font-weight: 500 !important;*/
}


.legend:hover{
  opacity: 1 !important;
}
.MuiDialog-paperWidthSm{
  width: 100%;
  height: 30vh;
}
.MuiDialog-paperWidthSm{
  background-color: blue;
}

.buttonSubmitGreen{
  background-color: #4caf50;
}
.buttonSubmitGreen:hover{
  background-color: #6fbf73;
}