.headingSectionBody{
    font-weight: 400;
    font-size: 17px;
    line-height: 24px !important;
    color: #000000;
}

.headingSectionTitle{
    font-size: 26px;
    line-height: 24px !important;
    color: #114675;
    padding: 0 0 0 10px;
    font-weight: 600;
}