.routeStatus {
  margin-left: 5em;
}

@media only screen and (max-width: 760px) {
  .routeStatus {
    margin-left: 0em;
  }
}

.buttonSubmit:hover{
  background-color: #3d5afe !important;
}


.MuiTab-textColorPrimary.Mui-selected{
  background-color: #229fd4;
  color: white !important;
}
.PrivateTabIndicator-colorPrimary-11{
  background-color: #229fd4 !important;
}