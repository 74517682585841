.descriptionHover{
    height: 5em;
    transition: height 300ms;
    width: 96.8%;
    overflow: hidden;
}

.desc-text-content{
    height: 30vh;
}

@media only screen and (max-width: 760px) {
    .desc-text-content{
        height: 40vh;
        margin: 10px 0 !important;
    }
    .descriptionHover:hover{
        height: 10em;
        transition: height 300ms;
    }
    .descriptionHover{
        height: 4.2em;
        transition: height 300ms;
        width: 100%;
    }
}

.desc-text-content:hover .descriptionHover{
    height: 29vh;
    overflow: hidden;
    transition: height 300ms;
}

.desc-text-content:hover .descriptionHover-content{
    display: block;
}
.descriptionHover-content{
    display: none;

}
