.verticleSliderCard1 .hiddenSegment2 {
    position: absolute;
    bottom: 0;
    left:0;
    right:0;
    top:70%;
    color: white;
    display: block;
    overflow: hidden;
    transition: all ease .5s;
    border-radius: 0px;
  }
  .verticleSliderCard1:hover{
    color: red;
    box-shadow: royalblue 10px 10px 25px;

  }
  .verticleSliderCard1 .textSecondary2{
      color: rgba(255, 255, 255, 0.85);
      display: none;
  }
/* 
  .verticleSliderCard:hover .textSecondary{
    display: none;
} */
  
  .verticleSliderCard1{
      position: relative; 
    background-position: center;
    object-fit: contain;
  }
  /* .verticleSliderCard:hover .hiddenSegment2{
    top:50px;
    transition: all ease .5s;
    opacity: 1;
    visibility: visible;
  } */

  @media only screen and (max-width: 760px) {
    .verticleSliderCard1 .hiddenSegment2 {
      background-color: #ebeaea;
      display: block;
      transition: height 300ms;
    }
    /* .verticleSliderCard:hover .hiddenSegment2{
      top:0;
      transition: height 300ms;
      opacity: 1;
    } */
  }
  