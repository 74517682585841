.verticleSliderCard .hiddenSegment {
    position: absolute;
    bottom: 0;
    left:0;
    right:0;
    top:70%;
    color: white;
    display: block;
    overflow: hidden;
    transition: all ease .5s;
    border-radius: 0px;
  }
  .verticleSliderCard .textSecondary{
      color: rgba(255, 255, 255, 0.85);
      display: none;
  }

  .verticleSliderCard:hover .textSecondary{
    display: block;
}
  
  .verticleSliderCard{
      position: relative; 
    background-position: center;
    object-fit: contain;
  }
  .verticleSliderCard:hover .hiddenSegment{
    top:0;
    transition: all ease .5s;
    opacity: 1;
    visibility: visible;
  }

  @media only screen and (max-width: 760px) {
    .verticleSliderCard .hiddenSegment {
      background-color: #ebeaea;
      display: block;
      transition: height 300ms;
    }
    .verticleSliderCard:hover .hiddenSegment{
      top:0;
      transition: height 300ms;
      opacity: 1;
    }
  }
  